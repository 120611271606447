<template>
  <div class="warp">
    <div class="main">
      <h3>订单信息</h3>
      <van-field label="订单编号" label-width="90" :value="itemList.orderNo" disabled />
      <van-field label="下单时间" label-width="90" :value="conversionTime(itemList.createTime)" disabled />
      <van-field v-if="itemList.appointmentEndTime" label="预约时间" label-width="90" :value="itemList.appointmentBeginTime + '-' + itemList.appointmentEndTime.substring(11)" disabled />
      <h3>收运信息</h3>
      <van-field v-if="itemList.appointmentEndTime" label="预约时间" label-width="90" :value="itemList.appointmentBeginTime + '-' + itemList.appointmentEndTime.substring(11)" disabled />
      <van-field label="产废企业" label-width="90" :value="itemList.companyName" disabled />
      <van-field label="清运地址" label-width="90" type="textarea" rows="2" :value="itemList.orderAddress" disabled />
      <van-field label="联系人" label-width="90" :value="itemList.trueName + '(' + itemList.userPhone + ')'" disabled />
      <van-field label="收运品类" label-width="90" :value="itemList.categoryName" disabled />
      <van-field label="给收运员留言" label-width="90" type="textarea" rows="2" :value="itemList.orderRemark" disabled />
      <div v-if="isShowxq" class="tab">
        <div class="add">
          <h3>收运明细</h3>
          <van-button type="default" size="small" @click="addClick(itemList.id)">添加</van-button>
        </div>
        <table border="1">
          <tr>
            <th>固废</th>
            <th>重量</th>
            <th v-if="this.$route.query.volumeValue || childrenList.volumeValue">体积</th>
            <th>操作</th>
          </tr>
          <tr v-for="(item, index) in itemList.list" :key="index">
            <td>{{item.categoryName}}</td>
            <td>{{item.weightValue ? item.weightValue + item.unit : item.weightRange}}</td>
            <td v-if="item.volumeValue">{{item.volumeValue + 'm³'}}</td>
            <td v-if="item.unit != '桶'" @click="editClick(item.id)"><p class="edit">编辑</p></td>
          </tr>
        </table>
        <h4>实际重量: {{weightValue ? weightValue + ' ' + itemList.list[0].unit : ''}}</h4>
        <div class="beizhu">
          <p>说明</p>
          <p>1. 收运费金额，由收运员实际收运称重后自行录入到系统</p>
          <p>2. 收运费金额具体的收取方式仅支持线下交易</p>
        </div>
        <div class="btn">
          <van-button round block type="info" @click="submit">确认提交</van-button>
          <van-button round block type="default" @click="goBackClick0">返回</van-button>
        </div>
      </div>
      <div v-else class="tab">
        <div class="add">
          <h3>收运明细</h3>
        </div>
        <table border="1">
          <tr>
            <th>固废</th>
            <th>重量</th>
            <th v-if="childrenList.volumeValue">体积</th>
            <th>合计</th>
          </tr>
          <tr v-for="(item, index) in itemList.list" :key="index">
            <td>{{item.categoryName}}</td>
            <td>{{item.weightValue ? item.weightValue + item.unit : item.weightRange}}</td>
            <td v-if="item.volumeValue">{{item.volumeValue ? item.volumeValue + 'm³' : '未填写'}}</td>
            <td>{{item.totalPrice}}元</td>
          </tr>
        </table>
        <div class="btn" v-if="isShowfh">
          <van-button round block type="info" @click="grabClick">抢单</van-button>
          <van-button round block type="default" @click="goBackClick1">返回</van-button>
        </div>
        <div class="btn" v-else>
          <van-button round block type="info" @click="goBackClick2">返回</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderconfirm, orderdetails, ordergrab } from '@/api/qyyorder.js'
import { Toast } from 'vant'
export default {
  data () {
    return {
      isShowxq: true,
      isShowfh: false,
      itemList: [],
      childrenList: [],
      weightValue: undefined,
    }
  },
  created() {
    if (this.$route.query.details) {
      this.isShowxq = false
    }
    if (this.$route.query.actives == 1) {
      this.isShowfh = true
    }
    this.orderdetails()
  },
  methods: {
    orderdetails() { // 获取订单详情
      const id = this.$route.query.id
      orderdetails(id).then(res => {
        if (res.data.code === 200) {
          this.itemList = res.data.data
          this.childrenList = res.data.data.list[0]
          this.weightValue = this.itemList.list.map(item => item.weightValue).reduce((a, b) => a + b)
        }
      })
    },
    addClick(orderId) { // 添加
      this.$router.push({path: 'Editorder', query: {orderId: orderId}})
    },
    editClick(id) { // 编辑
      this.$router.push({path: 'Editorder', query: {id: id}})
    },
    submit() { // 提交
      if (!this.weightValue) {
        Toast('请编辑物品信息，再提交')
      } else {
        const id = this.$route.query.id
        orderconfirm(id).then(res => {
          if (res.data.code === 200) {
            this.$router.push({path: 'Userorder', query: {actives: 3}})
            Toast('提交成功')
          }
        })
      }
    },
    goBackClick0() {
      if (this.$route.query.Userorder) {
        this.$router.push({path: 'Userorder', query: {actives: 2}})
      } else {
        this.$router.push({path: 'Userorder', query: {actives: 2}})
      }
    },
    goBackClick1() {
      if (this.$route.query.Userorder) {
        this.$router.push({path: 'Userorder', query: {actives: 1}})
      } else {
        this.$router.push({path: 'Userorder', query: {actives: 1}})
      }
    },
    goBackClick2() {
      if (this.$route.query.Userorder) {
        this.$router.push({path: 'Userorder', query: {actives: 3}})
      } else {
        this.$router.push({path: 'Userorder', query: {actives: 3}})
      }
    },
    grabClick() { // 抢单
      ordergrab(this.$route.query.id).then(res => {
        if (res.data.code === 200) {
          this.$router.push({path: 'Userorder', query: {actives: 2}})
          // location.reload()
          Toast('抢单成功')
        }
      })
    }
  }
}

</script>
<style lang="scss" scoped>
.warp {
  padding-bottom: 0;
  background-color: #fff;
  .main {
    h3 {
      margin: 10px 15px;
    }
    p {
      line-height: 23px;
      color: #999;
      span {
        color: #333;
      }
    }
    table {
      width: 100%;
      tr {
        .edit {
          margin: 0 5px;
          font-size: 14px;
          color: #07c160;
        }
        th, td {
          text-align: center;
          line-height: 30px;
        }
        th {
          font-weight: 600;
        }
      }
    }
    .tab {
      padding: 0 15px;
      border-top: 1px solid #ebedf0;
      .add {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;
        h3 {
          margin: 10px 0;
        }
      }
    }
    h4 {
      line-height: 40px;
    }
    .beizhu {
      p {
        font-size: 12px;
        line-height: 20px;
        color: #333;
      }
    }
  }
}
::v-deep .van-button--round {
  border-radius: 3px;
}
::v-deep .van-button--info {
  background-color: #0087f0;
  border: 1px solid #0087f0;
  margin-bottom: 10px;
}
::v-deep .van-cell {
  padding-top: 0;
  padding-bottom: 0;
}
::v-deep .van-cell::after {
  border: none;
}
::v-deep .van-field__control:disabled {
  color: #333;
  -webkit-text-fill-color: #333;
}
</style>
